import Axios from "axios";
let token = localStorage.getItem("accessToken") || "";

const axios = Axios.create({
    baseURL: `https://zelpay-api.zelbet.net/api/v1`,
    // baseURL: `http://128.199.219.200:4330/api/v1`,
    // baseURL: `https://payment-api.zerozb.me/api/v1`,

    // baseURL:
    //   process.env.NODE_ENV === "development"
    //     ? `http://192.168.68.122:4301/api/v1`
    //     : `https://api.payinservices.store/api/v1`,

    headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
    },
});

export default axios;
