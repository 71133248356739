// import { authRoles } from "./auth/authRoles";

export const navigations = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: "dashboard",
  },
  {
    label: "Bank",
    type: "label",
  },
  {
    name: "Bank",
    icon: "folder",
    children: [
      {
        name: "Add Bank",
        path: "/channel",
        iconText: "VO",
      },
      
      {
        name: "Bank List",
        path: "/bank-list",
        iconText: "OL",
      },
      {
        name: "Add Account",
        path: "/account-create",
        iconText: "OL",
      },
    ],
  },
  {
    label: "Deposit",
    type: "label",
  },
  {
    name: "Deposit",
    icon: "folder",
    children: [
      {
        name: "Pending Deposit List",
        iconText: "VO",
        path: "/pending-deposit-list",
      },
      {
        name: "Accepted Deposit List",
        iconText: "VO",
        path: "/accepted-deposit-list",
      },
      {
        name: "Rejected Deposit List",
        iconText: "VO",
        path: "/rejected-deposit-list",
      },
    ],
  },
  {
    label: "Withdraw",
    type: "label",
  },
  {
    name: "Withdraw",
    icon: "folder",
    children: [
      {
        name: "Pending Withdraw List",
        iconText: "VO",
        path: "/pending-withdraw-list",
      },
      {
        name: "Accepted withdraw List",
        iconText: "VO",
        path: "/accepted-withdraw-list",
      },
      {
        name: "Rejected withdraw List",
        iconText: "VO",
        path: "/rejected-withdraw-list",
      },
    ],
  },
  {
    name: "Signature key",
    icon: "folder",
    path: "/signKey",
  },
  {
    label: "Settings",
    type: "label",
  },
  {
    name: "Payment Settings",
    icon: "folder",
    path: "/payment-settings",
  },
  {
    name: "Change Password",
    icon: "folder",
    path: "/password",
  },
  
  
];
