import React from "react";

const pagesRoutes = [
  {
    path: "/dashboard",
    component: React.lazy(() => import("./dashboard/Analytics2")),
  },
  {
    path: "/create-order",
    component: React.lazy(() => import("./order/posOrder/posOrder")),
  },
  {
    path: "/order-list",
    component: React.lazy(() => import("./order/orderList/orderlist")),
  },
  {
    path: "/order-view/:serialId",
    component: React.lazy(() => import("./order/orderView/orderView")),
  },
  {
    path: "/create-product",
    component: React.lazy(() => import("./product/createProduct/createProducts")),
  },
  {
    path: "/update-product/:productSlug",
    component: React.lazy(() => import("./product/updateProduct/updateProducts")),
  },
  {
    path: "/customer",
    component: React.lazy(() => import("./customer/customerList")),
  },
  {
    path: "/product-list",
    component: React.lazy(() => import("./product/productList/productList")),
  },
  {
    path: "/create-category",
    component: React.lazy(() => import("./category/createCategory")),
  },
  {
    path: "/update-category/:categoryId",
    component: React.lazy(() => import("./category/updateCategory")),
  },
  {
    path: "/category-list",
    component: React.lazy(() => import("./category/CategoryList")),
  },
  {
    path: "/create-attribute",
    component: React.lazy(() => import("./attributes/createAttribute")),
  },
  {
    path: "/update-attribute/:attributeId",
    component: React.lazy(() => import("./attributes/updateAttribute")),
  },
  {
    path: "/attribute-list",
    component: React.lazy(() => import("./attributes/AttributeList")),
  },
  {
    path: "/create-attribute-value",
    component: React.lazy(() => import("./attributes/createAttributeValue")),
  },
  {
    path: "/update-attribute-value/:attributeValueId",
    component: React.lazy(() => import("./attributes/updateAttributeValue")),
  },
  {
    path: "/attribute-value-list",
    component: React.lazy(() => import("./attributes/AttributeValueList ")),
  },
  {
    path: "/create-brand",
    component: React.lazy(() => import("./brand/createBrand")),
  },
  {
    path: "/update-brand/:brandId",
    component: React.lazy(() => import("./brand/updateBrand")),
  },
  {
    path: "/brand-list",
    component: React.lazy(() => import("./brand/brandList")),
  },
  {
    path: "/channel",
    component: React.lazy(() => import("./Channel/sticker")),
  },
  {
    path: "/create-promo",
    component: React.lazy(() => import("./promo/createPromo")),
  },
  {
    path: "/update-promo/:promoId",
    component: React.lazy(() => import("./promo/updatePromo")),
  },
  {
    path: "/promo-list",
    component: React.lazy(() => import("./promo/promoList")),
  },
  {
    path: "/section/:sectionSlug",
    component: React.lazy(() => import("./section/sectionProducts/sectionProducts")),
  },
  {
    path: "/section",
    component: React.lazy(() => import("./section/sectionCrud/sectionDetails")),
  },
  {
    path: "/courier",
    component: React.lazy(() => import("./courier/courier")),
  },
  {
    path: "/divisions",
    component: React.lazy(() => import("./locations/divisions")),
  },
  {
    path: "/districts",
    component: React.lazy(() => import("./locations/district")),
  },
  {
    path: "/upazila",
    component: React.lazy(() => import("./locations/upazila")),
  },
  {
    path: "/delivery-charge",
    component: React.lazy(() => import("./deliveryCharge/deliveryCharge")),
  },
  {
    path: "/pop-up-img",
    component: React.lazy(() => import("./media/popupImg")),
  },
  {
    path: "/slider-img",
    component: React.lazy(() => import("./media/sliders")),
  },
  {
    path: "/brand-img",
    component: React.lazy(() => import("./media/brandImg")),
  },
  {
    path: "/profile",
    component: React.lazy(() => import("./general/profile")),
  },
  {
    path: "/password",
    component: React.lazy(() => import("./general/password")),
  },
  {
    path: "/account-create",
    component: React.lazy(() => import("./Bank/bankCreate"))
  },
  {
    path: "/bank-list",
    component: React.lazy(() => import("./Bank/bankList"))
  },
  {
    path: "/pending-deposit-list",
    component: React.lazy(() => import("./Deposit/depositList"))
  },
  {
    path: "/accepted-deposit-list",
    component: React.lazy(() => import("./Deposit/acceptedDepositList"))
  },
  {
    path: "/rejected-deposit-list",
    component: React.lazy(() => import("./Deposit/rejectedDepositList"))
  },
  {
    path: "/pending-withdraw-list",
    component: React.lazy(() => import("./Withdraw/pendingWithdraw"))
  },
  {
    path: "/accepted-withdraw-list",
    component: React.lazy(() => import("./Withdraw/acceptedWithdraw"))
  },
  {
    path: "/rejected-withdraw-list",
    component: React.lazy(() => import("./Withdraw/rejectedWithdraw"))
  },
  {
    path: "/single-bank-view/:channelSlug",
    component: React.lazy(() => import("./Bank/singleBankView")),
  },
  {
    path: "/signKey",
    component: React.lazy(() => import("./SignatureKey/signKey"))
  },
  {
    path: "/payment-settings",
    component: React.lazy(() => import("./PaymentSettings/paymentSetting"))
  },
];

export default pagesRoutes;
